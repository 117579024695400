const wrap = document.querySelector('.form-wrap')
const form = wrap.querySelector('form')
const ran_prev = wrap.querySelector('#ran_previously')
const ran_prev_position = wrap.querySelector('#ran_previously_position_wrapper')
const recaptchaKey = '6LfKXhElAAAAABj5CuDvkeMUIRMKdT5-P7E-LtvI';

const submitEndpoint = 'https://us-central1-il-muni-candidate-recruitment.cloudfunctions.net/signup'
// const submitEndpoint = 'http://localhost:5001/dpi-fringe-candidates/us-central1/signup'

ran_prev.addEventListener('change', function(){
    if(this.checked){
        ran_prev_position.classList.remove("hidden");
    } else {
        ran_prev_position.classList.add("hidden");
    }
})

form.addEventListener('submit', async e => {
    e.preventDefault()
    wrap.setAttribute('aria-busy', 'true')
    form.querySelector('button').setAttribute('disabled', 'disabled')
    wrap.style.minHeight = `${wrap.offsetHeight}px`


    const data = new FormData(form)
    const url = new URL(window.location)

    data.set('utm_source', url.searchParams.get('utm_source'))
    data.set('utm_campaign', url.searchParams.get('utm_campaign'))
    data.set('utm_medium', url.searchParams.get('utm_medium'))

    const resp = await fetch(submitEndpoint, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/x-www-form-urlencoded'
        },
        body: new URLSearchParams(data).toString()
    })
    const body = await resp.json()

    if (body.error) {
        console.log(error)
    }

    wrap.innerHTML = `
        <p class="thank-you">Thank you for applying! Our team will be in touch soon.</p>`
    wrap.setAttribute('aria-busy', 'false')
    window.scrollTo(0, 0)
    return false
})
